import React from 'react';

import Layout from '../components/layout/public';
import Hero from '../components/public/page-hero';
import Callout, { SocialMediaCallout } from '../components/public/callout';
import Pricing from '../components/public/pricing';
import Faq from '../components/public/faq';
import SEO from '../components/seo';

const PornBlockerPage = () => (
	<Layout>
		<SEO
			title="Block social media websites with DnsAdBlock"
			description={`Block social media websites and other unwanted content by switching to our DNS servers. Keep yourself and your family safe while browsing the internet.`}
		/>

		<Hero title={'Block social media'} style={{
			marginBottom: 50,
		}}/>

		<SocialMediaCallout style={{
			marginBottom: 50,
		}}/>

		<Pricing style={{
			marginBottom: 50,
		}}/>

		<Callout style={{
			marginBottom: 50,
		}}/>

		<Faq style={{
			marginBottom: 50,
		}}/>
	</Layout>
);


export default PornBlockerPage;
